import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div className="addbg2">
          <div className="wrapper">
            <header>
              <h1 className="logo">
                <a href="/">Neutrogena</a>
              </h1>
              <nav>
                <a href="/produkty/" className="link">
                  Produkty
                </a>
                <span>| </span>
                <a href="/neutrogena/o-nas/" className="link">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>| </span>
                <a href="/budte-krasna/" className="link">
                  Buďte krásná
                </a>
                 
              </nav>
            </header>
            <div className="content">
              <section className="main">
                <div className="text full">
                  <div className="law">
                    <h2>
                      <span
                        style={{
                          "vertical-align": "inherit"
                        }}
                        data-mce-mark="1"
                      >
                        <span
                          style={{
                            "vertical-align": "inherit"
                          }}
                          data-mce-mark="1"
                        >
                          <span
                            style={{
                              "vertical-align": "inherit"
                            }}
                            data-mce-mark="1"
                          >
                            <span
                              style={{
                                "vertical-align": "inherit"
                              }}
                              data-mce-mark="1"
                            >
                              <span
                                style={{
                                  "vertical-align": "inherit"
                                }}
                                data-mce-mark="1"
                              >
                                <span
                                  style={{
                                    "vertical-align": "inherit"
                                  }}
                                  data-mce-mark="1"
                                >
                                  Politika ochrany osobních údajů&nbsp;
                                </span>
                              </span>
                            </span>
                          </span>
                        </span>
                      </span>
                    </h2>
                    <p>&nbsp;&nbsp;</p>
                    <p style={{fontWeight: "bold"}}>Zásady ochrany osobních údajů šablony pro webové stránky podléhající GDPR</p>
                    <p align="center">&nbsp;</p>
                    <p>
                    Společnosti JNTL Consumer Health (Czech Republic) s.r.o. záleží na vašem soukromí. Chceme vás seznámit s tím, jak shromažďujeme, používáme a zveřejňujeme informace. Tyto zásady ochrany osobních údajů popisují, jak přistupujeme k informacím, které my nebo naši poskytovatelé služeb shromažďují prostřednictvím webových stránek nebo aplikací (dále „Služby“), které provozujeme a spravujeme a skrze které máte přístup k těmto zásadám ochrany osobních údajů. Tím, že nám poskytnete osobní údaje či využijete našich služeb, potvrzujete, že jste seznámeni s našimi zásadami o ochraně osobních údajů a rozumíte jim.
                    </p>
                    <p>&nbsp;</p>
                    <p style={{fontWeight: "bold"}}>POUŽITÍ NEZLETILÝMI</p>
                    <p>
                    Služby nejsou určeny osobám mladším 16 let a požadujeme, aby tito jednotlivci neposkytovali prostřednictvím těchto služeb osobní údaje. Pokud vaše dítě osobní údaje poskytlo a vy požadujete jejich smazání, spojte se prosím s námi dle návodu v kapitole Kontaktujte nás.
                    </p>
                    <p>&nbsp;</p>
                    <p style={{fontWeight: "bold"}}>SHROMAŽĎOVÁNÍ OSOBNÍCH ÚDAJŮ</p>
                    <p>
                    V některých případech vás požádáme o poskytnutí vašich osobních údajů, abyste mohli využívat určité funkce (jako je odběr novinek formou emailové komunikace, přístup k odbornému medicínskému obsahu nebo zpracování objednávek) nebo se účastnit konkrétní aktivity (jako je např. spotřebitelská soutěž nebo jiné akce). Budete informování o tom, které údaje jsou povinné a které volitelné.
                    </p>
                    <p>
                    Můžeme kombinovat osobní údaje, které nám poskytnete, s jinými osobními údaji, které jsme od vás získali, ať už na internetu nebo mimo něj, včetně např. historie vašich objednávek, nákupů, registrací, připojení k odbornému obsahu.  Můžeme je také kombinovat s informacemi, které o vás dostáváme od jiných společností Kenvue .
                    </p>
                    <p>
                    Pokud nám poskytnete jakékoli osobní údaje týkající se jiné osoby, prohlašujete, že máte pravomoc tak učinit, a povolujete nám používat údaje v souladu s těmito zásadami ochrany osobních údajů.
                    </p>
                    <p>&nbsp;</p>
                    <p style={{fontWeight: "bold"}}>CITLIVÉ ÚDAJE</p>
                    <p>
                    Pokud to nebudeme výslovně požadovat nebo vás k tomu nevyzveme, žádáme vás, abyste nám neposílali a nezveřejňovali prostřednictvím těchto služeb ani jiným způsobem citlivé osobní údaje (např. rodné číslo, číslo občanského průkazu, informace týkající se rasového nebo etnického původu, politických názorů, vyznání nebo jiného přesvědčení, zdravotního stavu, sexuálního života nebo sexuální orientace, trestní minulosti nebo členství v odborech nebo biometrické nebo genetické údaje).  
                    </p>
                    <p>&nbsp;</p>
                    <p style={{fontWeight: "bold"}}>AUTOMATICKÉ SHROMAŽĎOVÁNÍ OSOBNÍCH ÚDAJŮ A JEJICH POUŽITÍ</p>
                    <p>
                    Naše společnost a poskytovatelé našich služeb mohou některé údaje o vaší činnosti v rámci našich služeb shromažďovat automaticky.  Přečtěte si, prosím, <a href="/zasady-pouzivani-souboru-cookies/">Zásady používání souborů cookie</a>, v nichž najdete podrobné informace o souborech cookie a jiných technologiích, které sledují vaše aktivity v rámci Služeb. S výjimkou případů povolených platnými právními předpisy umísťujeme soubory cookie až poté, co jsme získali váš souhlas formou lišty upozorňující na soubory cookie nebo prostřednictvím centra preferencí. Zásady používání souborů cookie obsahují informace o tom, jak můžete tyto technologie zablokovat.  
                    </p>
                    <p>
                    Naše společnost a poskytovatelé našich služeb také automaticky shromažďují a používají osobní údaje následujícími způsoby:
                    </p>
                    <p>
                    <span style={{fontWeight: "bold"}}>Prostřednictvím vašeho prohlížeče</span>:&nbsp; Určité údaje se shromažďují prostřednictvím většiny prohlížečů. Mezi tyto informace patří adresa MAC (Media Access Control), typ počítače (Windows nebo Macintosh), rozlišení obrazovky, název a verze operačního systému, stejně jako typ a verze internetového prohlížeče.  Údaje o typu a ID vašeho zařízení také shromažďujeme, pokud Služby využíváte přes mobilní zařízení.  Tyto údaje používáme s cílem zajistit, aby naše online služby fungovaly správně.
                    </p>
                    <p>
                    <span style={{fontWeight: "bold"}}>IP adresa</span>:&nbsp; Adresa IP je číslo, které je poskytovatelem internetové služby automaticky přiřazeno počítači, který používáte. Kdykoli uživatel použije naše Služby, je automaticky identifikována adresa IP, která je zaznamenána v souborech protokolu spolu s časem návštěvy a konkrétními stránkami, které navštívil.  Sbírání IP adres je standardním postupem a mnoho online služeb ho provádí automaticky.  IP adresy používáme pro takové účely, jako je výpočet úrovně používání Služeb, pomoc při určení problémů serveru a spravování Služeb.  Podle vaší IP adresy můžeme také přibližně určit vaši polohu.
                    </p>
                    <p>
                    <span style={{fontWeight: "bold"}}>Informace o zařízení</span>:&nbsp; Můžeme shromažďovat také údaje o vašem mobilním zařízení, jako je např. jedinečný identifikátor zařízení, a to za účelem analýzy způsobu, jakým využíváte naše Služby.
                    </p>
                    <p>&nbsp;</p>
                    <p style={{fontWeight: "bold"}}>JAK POUŽÍVÁME A ZVEŘEJŇUJEME OSOBNÍCH ÚDAJŮ</p>
                    <p>
                    Údaje, které nám poskytnete, používáme a zveřejňujeme tak, jak je popsáno v okamžiku jejich shromáždění.  Prohlédněte si prosím část níže „Volby a přístup“ pro získání informací o tom, jak můžete odstoupit od určitých způsobů používání našich služeb a dalších užitečných informací.
                    </p>
                    <p>
                    Kde to požadují příslušné zákony, vyžádáme si od vás souhlas s užitím vašich osobních údajů v okamžiku jejich shromáždění.  Informace od vás či o vás můžeme také v případě nutnosti použít pro účely plnění smlouvy, splnění právní povinnosti (například v rámci farmakovigilančních povinností) nebo pro naše legitimní obchodní zájmy.  Naše legitimní obchodní zájmy mohou zahrnovat nějaké z či všechny z následujících případů:
                    </p>
                    <ul>
                      <li><span style={{fontWeight: "bold"}}>Poskytování funkčnosti Služby a plnění vašich požadavků.</span>
                      <ul>
                        <li>poskytnout vám funkčnost Služby a poskytnout vám související zákaznický servis;</li>
                        <li>odpovídat na vaše dotazy a plnit vaše požadavky, například zasílat vám dokumenty, které požadujete, nebo e-mailová upozornění;</li>
                        <li>zasílání důležitých informací týkajících se našeho vztahu s vámi nebo ohledně Služby, změn našich podmínek a zásad a / nebo jiných administrativních informací.</li>
                      </ul>
                      </li>
                    </ul>
                    <p>Budeme se zabývat těmito činnostmi za účelem řízení našeho smluvního vztahu s vámi a / nebo splnění zákonné povinnosti.</p>
                    <ul>
                      <li><span style={{fontWeight: "bold"}}>Dosažení našich obchodních účelů.</span>
                      <ul>
                        <li>pro analýzu dat například za účelem zlepšení účinnosti Služby;</li>
                        <li>pro audity k ověření, zda naše interní procesy fungují tak, jak byly zamýšleny a zda jsou v souladu s právními, regulačními nebo smluvními požadavky;</li>
                        <li>pro účely sledování podvodů a bezpečnosti, například pro detekci a prevenci kybernetických útoků nebo pokusů o spáchání krádeže identity;</li>
                        <li>pro vývoj nových produktů a služeb;</li>
                        <li>pro vylepšení, zlepšení nebo úpravy našich webových stránek nebo produktů a služeb;</li>
                        <li>pro identifikaci trendů využívání Služby, například pochopení, které části naší Služby jsou pro uživatele nejzajímavější; a</li>
                        <li>pro stanovení účinnosti našich propagačních kampaní, abychom mohli přizpůsobit naše kampaně potřebám a zájmům našich uživatelů.</li>
                      </ul>
                      </li>
                    </ul>
                    <p>Budeme se zabývat těmito činnostmi, abychom spravovali náš smluvní vztah s vámi, dodržovali zákonnou povinnost a / nebo protože máme oprávněný zájem.</p>
                    <ul>
                      <li><span style={{fontWeight: "bold"}}>Analýza osobních údajů za účelem poskytování personalizovaných služeb.</span>
                      <ul>
                        <li>lépe porozumět vám, abychom mohli přizpůsobit naše interakce s vámi a poskytnout vám informace a / nebo nabídky přizpůsobené vašim zájmům;</li>
                        <li>lépe porozumět vašim preferencím, abychom mohli prostřednictvím služby poskytovat obsah, o kterém se domníváme, že bude pro vás relevantní a zajímavý.</li>
                      </ul>
                      </li>
                      <li>
                      <p>Poskytujeme personalizované služby buď s vaším souhlasem, nebo proto, že máme oprávněný zájem.</p>
                      </li>
                    </ul>
                    <p>Údaje shromážděné pomocí Služeb zpřístupňujeme následujícím kategoriím příjemců:</p>
                    <ul>
                      <li>našim přidruženým společnostem pro účely popsané v těchto zásadách ochrany osobních údajů.  Seznam našich přidružených společností je k dispozici na <a href="https://investors.kenvue.com/financials-reports/sec-filings/default.aspx" target="_blank">https://investors.kenvue.com/financials-reports/sec-filings/default.aspx</a> - přístup k následným záznamům Kenvue u Komise pro burzu cenných papírů.  JNTL Consumer Health (Czech Republic) s.r.o. je odpovědná za správu společně užívaných osobních údajů;</li>
                      <li>našim partnerům (třetím stranám), se kterými sdílíme vlastnictví značky nebo se společně podílíme na propagaci;</li>
                      <li>našim poskytovatelům služeb (třetím stranám), kteří poskytují následující služby: webový hosting a správa, hosting mobilních aplikací, analýza dat, zpracování plateb, plnění objednávek, poskytování infrastruktury, služby IT, služby zákazníkům, emailové služby a služby přímé doručovací pošty, auditorské služby a jiné obdobné služby, za účelem umožnění poskytnutí služeb; a</li>
                      <li>jak umožňují příslušné zákony, dalším příjemcům v případě reorganizace, sloučení, prodeje, založení společného podniku, postoupení, převodu nebo jiné změny kontroly nad podnikem, majetkem nebo akciemi (včetně v souvislosti s insolvenčním či obdobným řízením).</li>
                    </ul>
                    <p>Dále používáme a zpřístupňujeme vaše údaje, pokud to považujeme za nutné či vhodné: (a) jak je povoleno právními postupy či platnými zákony, včetně zákonů mimo zemi vašeho trvalého pobytu; (b) v souladu s platnými zákony z důvodu reakce na požadavky veřejných a vládních orgánů, včetně veřejných a vládních orgánů mimo zemi vašeho trvalého pobytu; (c) pro prosazení našich podmínek; (d) pro ochranu práv, soukromí, bezpečnosti nebo majetku nás a/nebo našich poboček, vás či jiné osoby.  Dále můžeme použít a zveřejnit vaše údaje i jinými způsoby, pokud k tomu získáme váš souhlas.</p>
                    <p>Můžeme také používat a zveřejňovat osobní údaje, které jsme shromáždili automaticky, jak je popsáno výše v části „Automatické shromažďování osobních údajů a jejich použití“.</p>
                    <p>Pokud to umožňují platné zákony, můžeme pro jakýkoli účel používat a zveřejňovat údaje, které nejsou v podobě umožňující osobní identifikaci.  Pokud kombinujeme údaje, které neumožňují osobní identifikaci s údaji, které to umožňují (jako je např. kombinace vašeho jména a geografické polohy), zacházíme s kombinovanými údaji jako s osobními tak dlouho, dokud jsou kombinované.</p>
                    <p>&nbsp;</p>
                    <p style={{fontWeight: "bold"}}>VOLBY A PŘÍSTUP</p>
                    <p style={{fontWeight: "bold"}}>Vaše volby ohledně našeho používání a zveřejňování osobních údajů</p>
                    <p>
                    Udělený souhlas se zpracováním vašich osobních údajů můžete kdykoliv odvolat, a to tak, že se na nás obrátíte prostřednictvím formuláře <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p6#etd=::00X,v9u?z00Y00/d00L00c?Z9W00Y00rR6uV?R9V00L00&amp;Z9TvZTV00Y00XA00L00vZn9vOcV?00Y00iannpB53poKKnns500wwa$">zde</a>, případně tak, že navštívíte Službu a aktualizujete si svůj internetový profil. Můžete si přizpůsobit naše používání a zveřejňování vašich osobních údajů pro marketingové účely.  Můžete si vybrat z:
                    </p>
                    <ul>
                      <li><span style={{fontWeight: "bold"}}>Zasílání marketingových sdělení (o produktech a službách)</span>:&nbsp; Pokud již v budoucnu nechcete dále dostávat marketingová sdělení, můžete zrušit jejich zasílání prostřednictvím formuláře <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p6#etd=::00X,v9u?z00Y00/d00L00c?Z9W00Y00rR6uV?R9V00L00&amp;Z9TvZTV00Y00XA00L00vZn9vOcV?00Y00iannpB53poKKnns500wwa$">zde</a>. Ve své žádosti, prosím, uveďte své jméno, určete formu(y) marketingového sdělení, které již nechcete dostávat, a zadejte adresu(y), na které bylo sdělení zasíláno.  Pokud např. již nechcete dostávat marketingové e-maily nebo přímé e-maily, sdělte nám to a uveďte nám své jméno a e-mailovou nebo poštovní adresu.  Dále můžete zrušit zasílání marketingových e-mailů pomocí pokynů pro odhlášení uvedených v každé takové zprávě (odkaz v zápatí e-mailu).&nbsp;</li>
                      <li><span style={{fontWeight: "bold"}}>Zasílání upomínek</span>:&nbsp; Pokud již v budoucnu nechcete přijímat připomínky medicínské povahy, můžete zrušit jejich zasílání prostřednictvím formuláře <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p6#etd=::00X,v9u?z00Y00/d00L00c?Z9W00Y00rR6uV?R9V00L00&amp;Z9TvZTV00Y00XA00L00vZn9vOcV?00Y00iannpB53poKKnns500wwa$">zde</a>. Ve své zprávě, prosím, uveďte své jméno a e-mailovou adresu nebo telefonní číslo, na které dostáváte upomínky.</li>
                      <li><span style={{fontWeight: "bold"}}>Sdílení vašich osobních údajů přidruženým společnostem a partnerům (třetím stranám)</span>:&nbsp; Pokud jste v minulosti souhlasil/a se zasíláním marketingových sdělení našich přidružených společností nebo partnerů (třetích stran), můžete zrušit sdílení vašich osobních údajů s těmito stranami za účelem další přímé marketingové komunikace, tak, že nás zkontaktujete prostřednictvím formuláře <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p6#etd=::00X,v9u?z00Y00/d00L00c?Z9W00Y00rR6uV?R9V00L00&amp;Z9TvZTV00Y00XA00L00vZn9vOcV?00Y00iannpB53poKKnns500wwa$">zde</a>. Ve své odpovědi, prosím, uveďte, že dále již nesmíme sdílet vaše osobní údaje s našimi přidruženými společnostmi a/nebo partnery (třetími stranami) pro jejich marketingové účely, a sdělte své jméno a e-mailovou adresu.</li>
                    </ul>
                    <p>
                    Vaši žádost nebo žádosti se budeme snažit splnit, jakmile to bude možné.  Vezměte však, prosím, na vědomí, že pokud nebudete chtít dostávat žádné další materiály (jak je popsáno výše), nebudeme moci odstranit vaše osobní údaje z databází našich přidružených společností, kterým jsme již vaše osobní údaje sdělili (tj. kterým jsme je již předali před datem vaší žádosti o ukončení rozesílání marketingových materiálů). Budeme však vyvíjet přiměřené úsilí, abychom informovali naše přidružené společnosti o vaší žádosti. Vezměte, prosím, také na vědomí, že pokud zrušíte zasílání zpráv souvisejících s marketingem, můžeme vám nadále zasílat důležité transakční a administrativní zprávy, jejichž zasílání nemůžete zrušit.
                    </p>
                    <p>&nbsp;</p>
                    <p style={{fontWeight: "bold"}}>Jak získat přístup k osobním údajům nebo je změnit či smazat</p>
                    <p>
                    Máte právo kdykoliv požadovat přístup k vašim osobních údajů, jejich opravu nebo výmaz, popřípadě omezení zpracování, vznést námitku proti zpracování a máte také právo na přenositelnost údajů (do té míry, k jaké vás k těmto aktivitám opravňují příslušné předpisy na ochranu osobních údajů). Pokud si přejete využít svého práva, kontaktuje nás prosím prostřednictvím formuláře <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p6#etd=::00X,v9u?z00Y00/d00L00c?Z9W00Y00rR6uV?R9V00L00&amp;Z9TvZTV00Y00XA00L00vZn9vOcV?00Y00iannpB53poKKnns500wwa$">zde</a>.&nbsp; Na vaši žádost odpovíme v přiměřené lhůtě a ne později, než za jeden měsíc po obdržení žádosti. Pokud dojde k okolnostem, které způsobí zpoždění naší odpovědi, okamžitě vás informujeme a poskytneme vám datum, kdy na vaši žádost odpovíme.
                    </p>
                    <p>&nbsp;</p>
                    <p style={{fontWeight: "bold"}}>PŘESHRANIČNÍ PŘENOS</p>
                    <p>
                    Vaše osobní údaje mohou být uloženy a zpracovány v jakékoli zemi, ve které k tomu máme možnost, nebo kde máme poskytovatele služeb, a používáním našich stránek nebo poskytnutím souhlasu (pokud to vyžaduje zákon) souhlasíte s přenosem osobních údajů do zemí mimo zemi svého bydliště, včetně Spojených států (USA), kde mohou platit jiná pravidla ochrany osobních údajů než ve vaší zemi.  Nicméně ochranu vašich osobních údajů při přenosu k našim přidružených společnostem či třetím stranám v jiných zemí zajišťují vhodná smluvní a jiná opatření.
                    </p>
                    <p>
                    Evropská komise uznává některé ze zemí mimo Evropský hospodářský prostor (EHP) za schopné zajistit dostatečnou úroveň ochrany dat podle stanov EHP (vyčerpávající seznam těchto zemí naleznete <a href="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/adequacy-decisions_cs">zde</a>). U přenosů ze zemí EHP do zemí, jejichž úroveň ochrany Evropská komise nepovažuje za dostatečnou, jsme zajistili adekvátní ochranná opatření. V rámci těchto opatření jsme zajistili, aby byl příjemce vázán Standardními smluvními doložkami EU, za účelem ochrany vašich osobních údajů.  Kopii těchto opatření lze získat kontaktováním našeho pověřence pro ochranu osobních údajů (viz pokyny v části <em>„Kontaktujte nás“</em> níže).
                    </p>
                    <p>&nbsp;</p>
                    <p style={{fontWeight: "bold"}}>BEZPEČNOST</p>
                    <p>
                    Používáme přiměřená organizační, technická a správní opatření pro ochranu nám svěřených osobních údajů.  Žádný systém pro přenos dat přes Internet ani systém pro ukládání dat však bohužel nemůže zajistit absolutní bezpečnost.  Máte-li důvod se domnívat, že vaše interakce s námi již není bezpečná (pokud máte např. pocit, že byla ohrožena bezpečnost některého vašeho účtu u nás), okamžitě nás prosím na problém upozorněte v souladu s obsahem sekce <em>„Kontaktujte nás“</em> níže.
                    </p>
                    <p>&nbsp;</p>
                    <p style={{fontWeight: "bold"}}>DOBA UCHOVÁVÁNÍ ÚDAJŮ</p>
                    <p>
                    Vaše osobní údaje si ponecháme tak dlouho, jak bude potřebné či povolené vzhledem k účelu, za kterým jsme je shromáždili nebo, za předpokladu, že vaše osobní údaje zpracováváme na základě vašeho souhlasu, do odvolání vašeho souhlasu (podle toho, která skutečnost nastane dříve).Kritéria pro určení délky doby uchování údajů zahrnují:  (i) jak dlouho již trvá náš vztah s vámi a jak dlouho vám již poskytujeme naše služby; (ii) zda jsme subjektem nějaké právní povinnosti a (iii) zda je uchovávání údajů vhodné, vzhledem k naší právní pozici (například v případech promlčení, soudních sporů nebo regulačních vyšetřování).
                    </p>
                    <p>
                    Pokud jsme například shromáždili vaše osobní údaje z důvodu vyřízení vašeho požadavku, ponecháme si je po dobu po dobu nezbytnou k uvedenému účelu nebo do odvolání souhlasu (podle toho, která skutečnost nastane dříve).</p>
                    <p>Berte ale prosím na vědomí, že toto není vyčerpávající seznam možných období uchovávání údajů.  Vaše osobní údaje mohou být uchovávány i déle, podle kritérií uvedených v prvním odstavci této sekce, zvláště body (ii) a (iii).
                    </p>
                    <p>&nbsp;</p>
                    <p style={{fontWeight: "bold"}}>STRÁNKY A SLUŽBY TŘETÍCH STRAN</p>
                    <p>
                    Tato Služba může obsahovat odkazy na stránky třetích stran. S výjimkou případu, kdy to vyplývá z předpisů na ochranu osobních údajů, se tyto zásady ochrany osobních údajů se netýkají a my neneseme žádnou odpovědnost za soukromí, údaje nebo postupy třetích stran, včetně jakýchkoli třetích stran provozující jakékoliv stránky nebo webové služby (mimo jiné např. jakékoli aplikace), které jsou k dispozici prostřednictvím těchto stránek nebo na kterou tyto stránky odkazují.  Dostupnost nebo zahrnutí odkazu na jakékoli takové stránky nebo služby neznamená náš souhlas nebo souhlas jakýchkoliv našich přidružených společností s takovou stránkou nebo službou.
                    </p>
                    <p>&nbsp;</p>
                    <p style={{fontWeight: "bold"}}>KONTAKTUJTE NÁS</p>
                    <p>JNTL Consumer Health (Czech Republic) s.r.o. sídlící na adrese Praha 5, Walterovo náměstí 329/1, PSČ 15800, IČO: 41193075 je společnost odpovědná za shromáždění, použití a zveřejnění osobních údajů podle těchto zásad.</p>
                    <p>
                    Pokud máte jakékoli dotazy týkající se těchto zásad ochrany osobních údajů, obraťte se prosím na nás prostřednictvím formuláře <a href="https://www.ccc-consumercarecenter.com/UCUConfiguration?id=a075800001645p6#etd=::00X,v9u?z00Y00/d00L00c?Z9W00Y00rR6uV?R9V00L00&amp;Z9TvZTV00Y00XA00L00vZn9vOcV?00Y00iannpB53poKKnns500wwa$">zde</a>, nebo napište na následující adresu:
                    </p>
                    <p>&nbsp;&nbsp;&nbsp; JNTL Consumer Health (Czech Republic) s.r.o.</p>
                    <p>&nbsp;&nbsp;&nbsp; Walterovo náměstí 329/1, 158 00, Praha 5- Jinonice</p>
                    <p>
                    Můžete také kontaktovat našeho pověřence pro ochranu osobních údajů pro vaší zemi či region prostřednictvím <a href="mailto:emeaprivacy@kenvue.com">emeaprivacy@kenvue.com</a>.
                    </p>
                    <p>&nbsp;</p>
                    <p style={{fontWeight: "bold"}}>PODÁNÍ STÍŽNOSTI REGULÁTOROVI</p>
                    <p>
                    Můžete podat stížnost příslušným dohlížejícím orgánům ve vaší zemi či regionu.  Klikněte prosím <a href="https://edpb.europa.eu/about-edpb/board/members_cs">zde</a> pro kontaktní informace těchto orgánů.
                    </p>
                    <p>&nbsp;</p>
                    <p style={{fontWeight: "bold"}}>AKTUALIZACE TĚCHTO ZÁSAD OCHRANY OSOBNÍCH ÚDAJŮ</p>
                    <p>
                    Tyto zásady ochrany osobních údajů můžeme změnit.  Jakékoli změny v našich zásadách ochrany osobních údajů nabývají účinnosti dnem, kdy je revidovaná verze zásad ochrany osobních údajů zveřejněna prostřednictvím Služeb.  Používáním těchto Služeb po zveřejnění těchto změn znamená, že souhlasíte s revidovanou verzí těchto zásad.  Doporučujeme, abyste se při využívání Služeb pravidelně seznámili s aktuálním zněním zásad ochrany osobních údajů. Tyto zásady byly naposledy aktualizovány 02.06.2023.
                    </p>
                  </div>
                </div>
              </section>
            </div>
            <div id="fb_64"></div>
          </div>
        </div>
        <Footer></Footer>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        
      </div>
    );
  }
}

export default Page;
